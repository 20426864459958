import {Renderer as BaseRenderer} from "prosemirror-to-html-js";
import Bold from 'prosemirror-to-html-js/src/Marks/Bold';
import Code from 'prosemirror-to-html-js/src/Marks/Code';
import Italic from 'prosemirror-to-html-js/src/Marks/Italic';
import Blockquote from 'prosemirror-to-html-js/src/Nodes/Blockquote';
import BulletList from '../Nodes/BulletList';
import CodeBlock from 'prosemirror-to-html-js/src/Nodes/CodeBlock';
import Heading from 'prosemirror-to-html-js/src/Nodes/Heading';
import ListItem from '../Nodes/ListItem';
import OrderedList from '../Nodes/OrderedList';
import Paragraph from '../Nodes/Paragraph';
import InlineParagraph from '../Nodes/InlineParagraph';
import Highlight from '../Marks/Highlight';
import TextSize from '../Marks/TextSize';
import Image from 'prosemirror-to-html-js/src/Nodes/Image';
import Link from '../Marks/Link';
import LinkExternal from '../Marks/LinkExternal';
import Strikethrough from '../Marks/Strikethrough';
import Underline from '../Marks/Underline';
import Personalisation from '../Nodes/Personalisation';
import HardBreak from "../Nodes/HardBreak";
import {isJson} from "../verifyJSON";
export default class Renderer extends BaseRenderer {
  constructor (options) {
    super();
    this.document = undefined;
    const ParagraphNode = options && options.inline ? InlineParagraph : Paragraph;
    this.nodes = [
      Blockquote,
      BulletList,
      CodeBlock,
      Heading,
      HardBreak,
      ListItem,
      OrderedList,
      ParagraphNode,
      Image,
      HardBreak,
      Personalisation,
    ];
    if ( options && options.nodes ) {
      this.nodes.push(...options.nodes);
    }
    this.marks = [
      Bold,
      Code,
      Italic,
      Highlight,
      TextSize,
      Strikethrough,
      Underline,
    ];
    this.marks.push(options && options.noInternalLinks ? LinkExternal : Link);
    if ( options && options.noNodes ) {
      this.nodes = [
        Personalisation,
      ];
      if ( options && options.inline ) {
        this.nodes.push(InlineParagraph);
      }
      this.marks = [];
    }
  }
  render(value) {
    return ( isJson(value) ) ? super.render(value) : value;
  }
}
